<template>
  <div v-if="!isMobile">
    <div
      class="sticky top-[6rem] flex flex-col rounded-[12px] bg-white-100 px-[12px] py-[28px] shadow-drop-0 xl:px-[24px]"
    >
      <div
        class="flex cursor-pointer flex-col font-roboto text-[18px] leading-[120%] text-[#515151]"
      >
        <div
          v-for="item in navigationItemsNew"
          :key="item.id"
          class="mb-[24px] flex flex-col last:mb-0"
        >
          <Component
            :is="getItemComponent(item)"
            class="flex"
            v-bind="getItemAttrs(item)"
            @click="toggleNestedItems(item)"
          >
            <div class="grid h-5 place-content-center">
              <SvgIcon
                v-if="item.icon"
                :name="item.icon"
                class="h-4 w-4 min-w-[16px]"
              />
            </div>
            <span
              class="px-[8px] leading-5"
              :class="{ 'whitespace-nowrap': ![7, 12].includes(item.id) }"
              >{{ item.title }}</span
            >
            <SvgIcon
              v-if="item.nested?.length"
              :name="
                expandedMenuItems.includes(item.id)
                  ? 'chevron-up'
                  : 'chevron-down'
              "
              class="cursor-pointer"
            />
          </Component>
          <div
            v-if="item.nested && expandedMenuItems.includes(item.id)"
            class="ml-[24px] mt-[12px] flex flex-col gap-[12px] text-[16px]"
          >
            <NuxtLink
              v-for="subItem in item.nested"
              :key="`subItem-${subItem.id}`"
              :to="subItem.href"
              >{{ subItem.title }}</NuxtLink
            >
          </div>
        </div>
      </div>
      <div v-if="isDesktop" class="mt-5 flex flex-col font-roboto font-medium">
        <CommonLeftMenuBanner />
      </div>
    </div>
  </div>
</template>

<script setup>
import { navigationItemsNew } from '@/assets/data/navigation';

const NuxtLink = resolveComponent('NuxtLink');

const { isDesktop, isMobile } = useBreakpoint();
const expandedMenuItems = ref([]);

const { getItemComponent, getItemAttrs, toggleNestedItems } =
  useNavigationMenu(expandedMenuItems);

/*
// import TagsBlock from '@/components/Common/Blocks/TagsBlock'
import { navigationItemsNew } from '@/assets/data/navigation';
import LeftMenuBanner from '@/components/Common/LeftMenuBanner';
import getScreenBreakpoint from '@/mixins/getScreenBreakpoint';
import api from '@/services/api/api';

export default {
  components: { LeftMenuBanner },

  mixins: [getScreenBreakpoint],

  data: () => ({
    tagsList: [],
    navigationItemsNew,
    expandedMenuItems: [],
  }),

  async fetch() {
    try {
      const { data } = await api.articles.getTopArticleTags(8);
      this.tagsList = data.data;
    } catch (e) {
      console.log(e);
      this.$nuxt.error({ statusCode: e.response?.status ?? 503 });
    }
  },

  methods: {
    toggleNestedItems(item) {
      if (!item.nested?.length) {
        return;
      }

      if (this.expandedMenuItems.includes(item.id)) {
        this.expandedMenuItems = this.expandedMenuItems.filter(
          (id) => id !== item.id,
        );
      } else {
        this.expandedMenuItems.push(item.id);
      }
    },
  },
};*/
</script>

<template>
  <a
    href="https://vk.cc/cpCwmu"
    target="_blank"
    rel="nofollow"
    class="flex overflow-hidden rounded-xl"
  >
    <img
      v-lazy-load
      src="/images/banners/left-menu-banner.jpg"
      alt=""
      class="w-full object-cover"
    />
  </a>
</template>

<script setup></script>

<style lang="scss"></style>
